<template>
  <div class="list-wrap wrapper">
    <BaseLoad v-if="loading" />
    <div class="list" v-else>
      <div class="list-title">
        <h2>我的分类</h2>
        <button type="button" class="add-btn" @click="popShow = true">
          <i class="el-icon-circle-plus-outline"></i>
          添加目录
        </button>
      </div>
      <div class="cate-wrap">
        <div class="cate-title-wrap">
          <div class="cate-title">目录名称</div>
          <div class="cate-title">上级目录</div>
        </div>
        <ul class="cate-list">
          <li class="cate-item" v-for="item in list" :key="item.cate_id">
            <div class="cate-name">{{ item.name }}</div>
            <div class="cate-parent">{{ item.superior_title }}</div>
            <div class="cate-opera">
              <span class="opera" @click="editCate(item)">
                <i class="iconfont icon-bianji1"></i>
                修改
              </span>
              <span class="opera" @click="delCate(item.cate_id)">
                <i class="iconfont icon-shanchu"></i>
                删除
              </span>
            </div>
          </li>
        </ul>
      </div>
      <ListLoad v-if="listLoad && !loading" />
      <BaseEmpty v-if="!listLoad && !loading && list.length == 0" />
      <!-- 分页 -->
      <div class="pagination-con" v-if="list.length > 0 && total > 5">
        <el-pagination
          :page-size="pageSize"
          :pager-count="5"
          layout="prev, pager, next"
          :total="total"
          :current-page="page"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
      <!-- 分页end -->
      <!-- 添加或修改弹窗 -->
      <el-dialog
        :title="isEdit ? '修改分类' : '添加分类'"
        :visible.sync="popShow"
        width="500px"
        custom-class="cate-pop"
        center
        @close="closePop"
      >
        <ul class="cate-form">
          <li class="form-item">
            <label class="item-label">目录名称:</label>
            <input
              type="text"
              class="item-input"
              v-model="addForm.name"
              placeholder="请输入目录名称"
            />
          </li>
          <li class="form-item">
            <label class="item-label">上级目录:</label>
            <el-select
              v-model="addForm.superior_name"
              filterable
              allow-create
              default-first-option
              :disabled="addForm.superior_id == 0"
              placeholder="请选择或输入上级目录"
            >
              <el-option
                v-for="item in topList"
                :key="item.cate_id"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </li>
          <li class="form-item">
            <label class="item-label"></label>
            <p class="tip">如果输入上级目录，则自动创建一级目录</p>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closePop">取 消</el-button>
          <el-button type="primary" @click="addCate">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import BaseEmpty from "@/components/empty/BaseEmpty.vue";
import BaseLoad from "@/components/loading/BaseLoading.vue";
import ListLoad from "@/components/loading/ListLoading.vue";
import { handlePreview } from "@/tools/GlobalFunction.js";
export default {
  components: {
    BaseEmpty,
    BaseLoad,
    ListLoad,
  },
  data() {
    return {
      listLoad: false,
      loading: true,
      list: [],
      page: 1,
      total: 0,
      pageSize: 5,
      popShow: false,
      topList: [],
      addForm: {
        name: "",
        superior_name: "",
      },
      isEdit: false,
    };
  },
  created() {
    let _self = this;
    _self.getCateList(_self.page);
    _self.getCateTree();
  },
  methods: {
    //游戏分类（分页）
    getCateList(page) {
      let _self = this;
      _self.axios
        .post(config.url.getListOfPage, _self.$qs.stringify({ page }))
        .then((res) => {
          if (res.code == 200) {
            let list = res.data.list;
            _self.list = list;
            _self.total = res.data.total;
            _self.page = page;
            _self.loading = false;
            _self.listLoad = false;
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
            _self.loading = false;
            _self.listLoad = false;
          }
        });
    },
    //游戏分类（树形）
    getCateTree() {
      let _self = this;
      _self.axios.post(config.url.treeCate).then((res) => {
        if (res.code == 200) {
          _self.topList = res.data && res.data.length > 0 ? res.data : [];
          _self.loading = false;
          _self.listLoad = false;
        } else if (res.code != 200 && res.code != 401) {
          _self.showTip("error", res.msg);
          _self.loading = false;
          _self.listLoad = false;
        }
      });
    },
    // hover
    handleMouseEnter(index) {
      let _self = this;
      _self.list.forEach((item) => (item.show = false));
      let item = _self.list[index];
      item.show = true;
      _self.$set(_self.list, index, item);
    },
    // 预览
    handlePreview(id, type) {
      let _self = this;
      if (type == 1) {
        _self.$router.push({
          name: "course-info",
          params: {
            courseId: id,
          },
        });
      }
      if (type == 2) {
        _self.$router.push({
          name: "level-info",
          params: {
            levelId: id,
          },
        });
      }
      if (type == 3) {
        handlePreview(id);
      }
    },
    // 发布到工作台
    handleWorkbench(status, index, item) {
      let _self = this;
      item.issue_status = status;
      _self.$set(_self.list, index, item);
    },
    // 取消发布到工作台
    workbenchCancel(status, index, item) {
      let _self = this;
      item.issue_status = status;
      _self.$set(_self.list, index, item);
    },
    // 改变分页
    handleCurrentChange(val) {
      let _self = this;
      _self.page = val;
      _self.getCateList(val);
    },
    // 删除分类
    delCate(cate_id) {
      let _self = this;
      _self
        .$confirm("确认要删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          confirmButtonClass: "orange-btn",
        })
        .then(() => {
          _self.axios
            .post(config.url.delCate, _self.$qs.stringify({ cate_id }))
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                _self.currentType = 0;
                _self.getCateList(1);
              } else if (res.code != 200 && res.code != 401) {
                _self.showTip("error", res.msg);
                _self.loading = false;
                _self.listLoad = false;
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 修改分类
    editCate(item) {
      console.log(item);
      let _self = this;
      _self.addForm = {
        cate_id: item.cate_id,
        name: item.name,
        superior_id: item.superior_id,
        superior_name: item.superior_title,
      };
      _self.isEdit = true;
      _self.popShow = true;
    },
    // 弹窗确定
    addCate() {
      let _self = this;
      let form = _self.addForm;
      if (form.name.trim() == "") {
        _self.showTip("error", "请填写目录名称");
      } else if (form.superior_name.trim() == "") {
        _self.showTip("error", "请填写顶级目录");
      } else {
        let params = {
          superior_name: form.superior_name,
        };
        if (form.cate_id) {
          _self.changeCate(
            config.url.editCate,
            Object.assign(params, {
              cate_id: form.cate_id,
              cate_name: form.name,
            })
          );
        } else {
          _self.changeCate(
            config.url.insertCate,
            Object.assign(params, {
              name: form.name,
            })
          );
        }
      }
    },
    // 关闭弹窗
    closePop() {
      let _self = this;
      _self.addForm = {
        name: "",
        superior_name: "",
      };
      _self.popShow = false;
    },
    // 修改或添加分类
    changeCate(url, params) {
      let _self = this;
      _self.loading = true;
      _self.axios.post(url, _self.$qs.stringify(params)).then((res) => {
        if (res.code == 200) {
          _self.showTip("success", res.msg);
          _self.loading = false;
          _self.listLoad = false;
          _self.closePop();
          _self.getCateList(1);
          _self.getCateTree();
        } else if (res.code != 200 && res.code != 401) {
          _self.showTip("error", res.msg);
          _self.loading = false;
          _self.listLoad = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.list {
  width: 980px;
  padding: 30px;
  box-sizing: border-box;
  background: #fff;
  border-radius: $border-radius-main;
  .list-title {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    .add-btn {
      width: 140px;
      height: 30px;
      background: $color-main;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #fff;
      margin-left: 20px;
      cursor: pointer;
      i {
        margin-right: 6px;
      }
    }
  }
  .cate-title-wrap {
    display: flex;
    height: 50px;
    background: #fff7f1;
    margin-bottom: 16px;
    .cate-title {
      width: 350px;
      height: 100%;
      box-sizing: border-box;
      line-height: 50px;
      font-size: 20px;
      font-weight: bold;
    }
    .cate-title:first-child {
      padding-left: 30px;
    }
  }
  .cate-item {
    display: flex;
    .cate-name,
    .cate-parent {
      width: 350px;
      padding: 16px 0;
      font-size: 18px;
      color: #6b6b6b;
      flex-shrink: 0;
      box-sizing: border-box;
    }
    .cate-name {
      padding-left: 30px;
      position: relative;
    }
    .cate-name::before {
      content: "";
      width: 10px;
      height: 10px;
      position: absolute;
      left: 10px;
      top: 50%;
      margin-top: -5px;
      border-radius: 50%;
      background: $color-main;
    }
    .cate-opera {
      padding: 16px 30px 16px 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 220px;
      span {
        font-size: 18px;
        color: #ababab;
        margin-left: 25px;
        cursor: pointer;
        .icon-bianji1 {
          color: $color-second;
        }
        .icon-shanchu {
          color: $color-main;
        }
      }
    }
  }
  ::v-deep .cate-pop {
    border-radius: 20px !important;
  }
  .form-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    margin: 0 auto 20px;
    .item-label {
      font-size: 16px;
      color: #636363;
      margin-right: 10px;
      flex-shrink: 0;
    }
    .item-input {
      padding: 15px;
      font-size: 16px;
      line-height: 1;
      width: 100%;
      border: 1px solid #e5e5e5;
      color: #636363;
      outline: none;
    }
    .item-input::placeholder {
      color: #bebebe;
    }
    .item-input:focus {
      border-color: $color-main;
    }
    .el-select {
      font-size: 16px;
      line-height: 1;
      width: 100%;
      ::v-deep .el-input__inner {
        width: 100% !important;
      }
    }
    .tip{
      font-size: 14px;
      color: $color-main;
      margin-top: -10px;
    }
  }
  .form-item:last-child {
    margin-bottom: 0;
  }
  .cate-pop {
    .el-button {
      border: 1px solid $color-main;
      border-radius: 100px;
      padding: 10px 30px;
    }
    .el-button--primary {
      background-color: $color-main;
    }
    .el-button:focus,
    .el-button:hover {
      background: #fff7f1;
      color: $color-main;
    }
    .el-button--primary:focus,
    .el-button--primary:hover {
      background-color: $color-main;
      color: #fff;
    }
    ::v-deep .el-dialog--center .el-dialog__footer {
      padding: 0 20px 40px !important;
    }
  }
}
</style>
<style>
.el-dialog__footer {
  padding: 0 20px 40px !important;
}
</style>
